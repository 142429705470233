<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        md="11">
        <h1>Master Data For Audience</h1>
      </v-col>
    </v-row>
    <v-card class="mt-5">
      <v-card-title>Master Data</v-card-title>
      <v-divider />
      <v-card-text>
        <import-master-data :loadingPrimaryFile="GetLoading" />
        <h3
          v-if="pagination.total !== 0"
          class="subtitle mt-5">Data Master Total: {{ pagination.total }}</h3>
        <master-data-list 
          :paging="pagination"
          :masterDataAudience="GetMasterList.masters" 
          :headerMaster="headerMaster" />
        <div class="text-center">
        <!-- <v-pagination
          v-model="pagination.page"
          :length="15"
          :total-visible="7"></v-pagination> -->
      </div>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <!-- <btn-job :job-info.sync="jobData" /> -->
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
    MasterDataList: () => import('@/components/customAudience/masterData.vue'),
    ImportMasterData: () => import('@/components/customAudience/importMasterData.vue')
  },

  data () {
    return {
      masterDatas: [],
      pagination: {
        page: 1,
        size: 30,
        total: 0,
        pageCount: 0
      },
      headerMaster: [],
    }
  },

  async created() {
    await this.getDataMaster();
  },

  computed: {
    ...mapGetters({
      GetHeadersMaster: 'audiences/GET_MASTER_HEADER',
      GetLoading: 'audiences/GET_UPLOAD_AUDINCE_STATUS',
      GetMasterList: 'audiences/GET_MASTER_LIST'
    })
  },

  watch: {
    'GetHeadersMaster.master': function (val) {
      val.headers.map((h) => {
        return this.headerMaster.push({
          text: h,
          value: h
        })
      })
    },

    GetMasterList (val) {
      this.pagination.total = val.itemTotal.toLocaleString()
    },

    'pagination.page': async function (val) {
      console.log(val)
      await this.$store.dispatch('audiences/GetAudinceMasters', this.pagination);
    }
  },

  methods: {
    async getDataMaster() {
      await this.$store.dispatch('audiences/GetAudinceMasters', this.pagination);
      await this.$store.dispatch('audiences/GetAudinceMasterHeader');
    },
  }
}
</script>

<style>

</style>